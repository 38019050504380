import SearchIcon from '@mui/icons-material/Search';
import {
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import {parse, stringify} from 'query-string';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDataTableStyles} from './styles';
import {Checkbox as JoyCheckbox} from '../../../../components/joy-ui/forms/Checkbox';
import {replace, selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import hiwayLocalStorage from '../../../config/storage';
import {requiredIf} from '../../../utils/conditional-prop-type';
import Checkbox from '../Checkbox';
import DataTableCell from '../DataTableCell/DataTableCell';
import DataTableColumnFilters from '../DataTableColumnFilters/DataTableColumnFilters';
import DataTableFooter from '../DataTableFooter/DataTableFooter';
import DataTableHeader from '../DataTableHeader/DataTableHeader';
import DataTableHeaderColumns from '../DataTableHeaderColumns/DataTableHeaderColumns';
import DataTableLoader from '../DataTableLoader/DataTableLoader';
import DataTableTabFilters from '../DataTableTabFilters/DataTableTabFilters';

const extractQueryParams = (query, tableId, columnConfig) => {
    const filterableColumns = columnConfig.filter(config => !!config.columnFilterConfig);

    const extractedParams = {
        page: parseInt(query[`${tableId}-page`], 10) || 0,
        searchTerm: decodeURIComponent(query[`${tableId}-searchTerm`] || ''),
        sortBy: decodeURIComponent(query[`${tableId}-sortBy`] || ''),
        sortDirection: decodeURIComponent(query[`${tableId}-sortDirection`] || ''),
        tabFilterValue: decodeURIComponent(query[`${tableId}-tabFilter`] || ''),
        rowsPerPage: parseInt(query[`${tableId}-rowsPerPage`], 10) || null,
        filters: {},
    };

    filterableColumns.forEach(filterableColumn => {
        const key = filterableColumn.columnFilterConfig.filterKey || filterableColumn.key;

        if (!query[`${tableId}-${key}`]) {
            return;
        }

        const type = filterableColumn.columnFilterConfig.type;

        extractedParams.filters[key] = decodeURIComponent(query[`${tableId}-${key}`] || (type === 'select' ? 'SHOW_ALL' : ''));
    });

    return extractedParams;
};

const getInitialColumnFilterState = (columnConfig, filters) => {
    const initialState = {};

    columnConfig.forEach(column => {
        if (column.columnFilterConfig) {
            const key = column.columnFilterConfig?.filterKey || column.key;

            if (column.columnFilterConfig.type === 'string') {
                initialState[key] = filters[key] || '';
            } else if (column.columnFilterConfig.type === 'select') {
                initialState[key] = filters[key] || 'SHOW_ALL';
            }
        }
    });

    return initialState;
};

const DataTable = props => {
    const {
        tableId,
        title,
        titleCount,
        hasColumnFilters,
        columnConfig,
        defaultSortByKey,
        defaultSortDirection,
        dataSelector,
        isLoading,
        missingDataMessage,
        hasCheckboxes,
        hasDownloadAsZipButton,
        onDownloadAsZipButtonClick,
        isDownloadingAsZip,
        hasSearch,
        hasPagination,
        searchPlaceholder,
        hasTabFilters,
        tabFilterConfig,
        tabFilterKey,
        searchBy,
        additionalFilters,
        selectKey,
        defaultRowsPerPage,
        hasPaper,
        TableContainerProps,
        TableHeadProps,
        forceSortDirection,
        forceSortBy,
        onRowClick,
        isUseSavedRows,
        customHeader,
        rowsPerPageOptions,
        isAllDisplayed,
        initialTabFilterValue,
        onSelectedChange,
        isJoyTheme,
        isRowSelectionDisabled, // TODO This is function, so name should end on Fn or something similar
        shouldDeselectOnRowsChange,
    } = props;

    const styles = useDataTableStyles({isJoyTheme});
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    /**
     * HPD-2287 - we don't want to update the component after each 'key' change
     * see: shouldComponentUpdate method in src/lib/router/connected-router-saga/ConnectedRouter.js
     */
    const location = omit(useSelector(selectRouterLocation), 'key');
    const extractedParams = extractQueryParams(location.query, tableId, columnConfig);

    const isInitialMount = useRef(true);

    const storageKey = `${tableId}-savedRowsPerPage`;
    const savedRowsPerPage = useMemo(() => {
        if (!isUseSavedRows) {
            return defaultRowsPerPage;
        }
        return hiwayLocalStorage.has(storageKey)
            ? parseInt(hiwayLocalStorage.get(storageKey), 10)
            : defaultRowsPerPage;
    }, [isUseSavedRows, defaultRowsPerPage, storageKey]);

    const [columnFilters, setColumnFilters] = useState(getInitialColumnFilterState(
        columnConfig,
        extractedParams?.filters,
    ));

    const [areColumnFiltersOpen, setAreColumnFiltersOpen] = useState(
        !!Object.keys(extractedParams.filters).length || false,
    );

    const [sortBy, setSortBy] = useState(extractedParams.sortBy || defaultSortByKey);
    const [sortDirection, setSortDirection] = useState(extractedParams.sortDirection || defaultSortDirection);
    const [rowsPerPage, setRowsPerPage] = useState(extractedParams.rowsPerPage || savedRowsPerPage);
    const [page, setPage] = useState(extractedParams.page || 0);
    const [selected, setSelected] = useState([]);
    const [searchTerm, setSearchTerm] = useState(extractedParams.searchTerm || '');
    const [nonDebouncedSearchTerm, setNonDebouncedSearchTerm] = useState(extractedParams.searchTerm || '');
    const [tabFilterValue, setTabFilterValue] = useState(extractedParams.tabFilterValue || initialTabFilterValue);
    const [isSearchActive, setIsSearchActive] = useState(false);

    const {data: rows, count} = useSelector(dataSelector({
        sortBy,
        sortDirection,
        filter: columnFilters,
        columnConfig,
        pagination: {
            page,
            rowsPerPage,
        },
        searchTerm,
        tabFilterValue,
        tabFilterKey,
        searchBy,
    }));

    const createColumnFilterChangeHandler = useCallback(key => event => {
        setPage(0);

        setColumnFilters(prevState => ({
            ...prevState,
            [key]: event.target.value,
        }));

        setSelected([]);
    }, []);

    const handleSelectAllClick = useCallback(event => {
        if (event.target.checked) {
            setSelected(rows.filter(row => !isRowSelectionDisabled?.(row)).map(row => row.id));

            return;
        }

        setSelected([]);
    }, [isRowSelectionDisabled, rows]);

    const handleSelectClick = useCallback((event, rowId) => {
        if (event.target.checked) {
            setSelected(prevState => [...prevState, rowId]);

            return;
        }

        setSelected(prevState => prevState.filter(selectedId => selectedId !== rowId));
    }, []);

    const handleDownloadAsZipButtonClick = useCallback(() => {
        onDownloadAsZipButtonClick(selected.map(rowId => {
            const row = rows.find(row => row.id === rowId);

            return row[selectKey];
        }), () => {
            setSelected([]);
        });
    }, [onDownloadAsZipButtonClick, rows, selectKey, selected]);

    const handleColumnFilterToggleClick = useCallback(() => {
        setAreColumnFiltersOpen(prevState => {
            if (prevState) {
                // Filter was open, now it is going to be closed
                setColumnFilters(getInitialColumnFilterState(columnConfig, extractedParams));
                setPage(0);
            }

            return !prevState;
        });

        setSelected([]);
    }, [columnConfig, extractedParams]);

    const handleRowsPerPageChange = useCallback(event => {
        const {value} = event.target;
        setRowsPerPage(value);
        hiwayLocalStorage.set(storageKey, value);

        setPage(0);

        setSelected([]);
    }, [storageKey]);

    const handlePageChange = useCallback((_, page) => {
        setPage(page);
        setSelected([]);
    }, []);

    const handleSortClick = useCallback(newSortBy => {
        setPage(0);
        setSelected([]);

        if (sortBy === newSortBy) {
            // Change direction
            setSortDirection(prevState => {
                if (prevState === 'desc') {
                    return 'asc';
                } else if (prevState === 'asc') {
                    setSortBy(defaultSortByKey);

                    return 'desc';
                }

                return 'desc';
            });

            return;
        }

        setSortBy(newSortBy);
    }, [defaultSortByKey, sortBy]);

    const handleSearchTermChange = useCallback(event => {
        setNonDebouncedSearchTerm(event.target.value);

        // On search change we need to reset page
        setPage(0);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSearchTerm(nonDebouncedSearchTerm);
        }, 300);
        return () => clearTimeout(timeoutId);
    }, [nonDebouncedSearchTerm]);

    const handleTabFilterChange = useCallback((event, value) => {
        setTabFilterValue(value);
    }, []);

    const filterableColumns = columnConfig.filter(config => !!config.columnFilterConfig);

    useEffect(() => {
        if (forceSortDirection) {
            setSortDirection(forceSortDirection);
        } else if (sortDirection !== defaultSortDirection) {
            setSortDirection(defaultSortDirection);
        }

        // eslint-disable-next-line
    }, [forceSortDirection]);

    useEffect(() => {
        if (forceSortBy) {
            setSortBy(forceSortBy);
        } else if (sortBy !== defaultSortByKey) {
            setSortBy(defaultSortByKey);
        }

        // eslint-disable-next-line
    }, [forceSortBy]);

    useEffect(() => {
        columnConfig.forEach(column => {
            if (!column?.columnFilterConfig) {
                return;
            }

            if (column?.columnFilterConfig?.type !== 'select') {
                return;
            }

            if (!column?.columnFilterConfig?.options || !column?.columnFilterConfig?.options.length) {
                return;
            }

            const key = column?.columnFilterConfig?.filterKey || column?.key;

            if (columnFilters[key] === 'SHOW_ALL') {
                return;
            }

            const filterValueExists = column?.columnFilterConfig?.options
                .find(option => option.key === columnFilters[key]);

            if (!filterValueExists) {
                setColumnFilters({
                    ...columnFilters,
                    [key]: 'SHOW_ALL',
                });
            }
        });
        // eslint-disable-next-line
    }, [columnConfig]);

    useEffect(() => {
        if (isLoading || !count) {
            return;
        }

        if (page > Math.max(0, Math.ceil(count / rowsPerPage) - 1)) {
            setPage(0);
        }
        // eslint-disable-next-line
    }, [count]);

    useEffect(() => {
        if (isInitialMount.current) {
            // TODO Perhaps it is better to remove this removal in the code, once it is found
            // sortBy and sortDirection is being removed on returning back to the table (back browser button)
            if (extractedParams.sortBy) {
                setSortBy(extractedParams.sortBy);
            }
            if (extractedParams.sortDirection) {
                setSortDirection(extractedParams.sortDirection);
            }

            isInitialMount.current = false;
            return;
        }

        const excludedParams = [
            `${tableId}-page`,
            `${tableId}-searchTerm`,
            `${tableId}-sortBy`,
            `${tableId}-sortDirection`,
            `${tableId}-tabFilter`,
            `${tableId}-rowsPerPage`,
            ...filterableColumns.filter(filterableColumn => {
                return filterableColumn.columnFilterConfig.filterKey || filterableColumn.key;
            }),
        ];

        const params = omit(parse(location.search), excludedParams);

        if (page) {
            params[`${tableId}-page`] = page;
        }

        if (searchTerm) {
            params[`${tableId}-searchTerm`] = searchTerm;
        }

        if (sortBy && sortBy !== defaultSortByKey) {
            params[`${tableId}-sortBy`] = sortBy;
        }

        if (sortDirection && sortDirection !== defaultSortDirection) {
            params[`${tableId}-sortDirection`] = sortDirection;
        }

        if (tabFilterValue && tabFilterValue !== initialTabFilterValue) {
            params[`${tableId}-tabFilter`] = tabFilterValue;
        }

        if (rowsPerPage && rowsPerPage !== savedRowsPerPage) {
            params[`${tableId}-rowsPerPage`] = rowsPerPage;
        }

        filterableColumns.forEach(filterableColumn => {
            const key = filterableColumn.columnFilterConfig.filterKey || filterableColumn.key;

            if (columnFilters[key] && columnFilters[key] !== 'SHOW_ALL') {
                params[`${tableId}-${key}`] = columnFilters[key];
            } else if (params[`${tableId}-${key}`] && (columnFilters[key] === 'SHOW_ALL' || !columnFilters[key])) {
                delete params[`${tableId}-${key}`];
            }
        });

        const queryParams = stringify(params);

        if (queryParams === stringify(location.query)) {
            return;
        }

        dispatch(replace({
            search: queryParams,
        }));
        // eslint-disable-next-line
    }, [page, searchTerm, sortBy, sortDirection, tabFilterValue, columnFilters, rowsPerPage]);

    useEffect(() => {
        if ((extractedParams.page || extractedParams.page === 0)) {
            if (extractedParams.page <= Math.max(0, Math.ceil(count / rowsPerPage) - 1)) {
                setPage(extractedParams.page);
            }
        }

        if (!extractedParams.searchTerm) {
            setSearchTerm('');
        }

        if (!extractedParams.sortBy && !forceSortBy) {
            setSortBy(defaultSortByKey);
        }

        if (!extractedParams.sortDirection && !forceSortDirection) {
            setSortDirection('desc');
        }

        if (!extractedParams.tabFilterValue) {
            setTabFilterValue(initialTabFilterValue);
        }

        if (!extractedParams.rowsPerPage) {
            setRowsPerPage(savedRowsPerPage);
        }

        filterableColumns.forEach(filterableColumn => {
            const key = filterableColumn.columnFilterConfig.filterKey || filterableColumn.key;
            const type = filterableColumn.columnFilterConfig.type;

            if (!extractedParams.filters[key] && columnFilters[key] !== (type === 'select' ? 'SHOW_ALL' : '')) {
                setColumnFilters({
                    ...columnFilters,
                    [key]: type === 'select' ? 'SHOW_ALL' : '',
                });
            }
        });
        // eslint-disable-next-line
    }, [location.query]);

    useEffect(() => {
        onSelectedChange?.(selected);
    }, [selected, onSelectedChange]);

    useEffect(() => {
        if (shouldDeselectOnRowsChange) {
            // Check for length needed for HPD-6169
            // If there is no check it sets new empty array, which runs re-render every time and creates infinite loop
            if (selected?.length > 0) {
                setSelected([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, shouldDeselectOnRowsChange]);

    const content = useMemo(() => {
        if (isLoading) {
            return (
                <DataTableLoader
                    hasCheckboxes={hasCheckboxes}
                    numberOfRows={rows.length && rows.length < rowsPerPage ? rows.length : rowsPerPage}
                    columnConfig={columnConfig}
                />
            );
        }

        if (!rows.length) {
            return (
                <TableRow>
                    <TableCell align="center" colSpan={100}>
                        <Typography>
                            {missingDataMessage}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        }

        return rows.map(row => (
            <TableRow
                className={styles.tableRow}
                hover
                key={row.id}
                onClick={e => {
                    if (onRowClick) {
                        onRowClick(e, row);
                    }
                }}
                sx={{
                    cursor: onRowClick ? 'pointer' : 'inherit',
                    ...(isJoyTheme && {
                        backgroundColor: selected.includes(row.id)
                            ? 'var(--joy-palette-neutral-50) !important'
                            : 'white',
                    }),
                }}
            >
                {hasCheckboxes && (
                    <TableCell padding="checkbox">
                        {isJoyTheme
                            ? (
                                <JoyCheckbox
                                    onChange={event => handleSelectClick(event, row.id)}
                                    checked={selected.includes(row.id)}
                                    disabled={isRowSelectionDisabled(row)}
                                />
                            )
                            : (
                                <Checkbox
                                    onClick={event => handleSelectClick(event, row.id)}
                                    checked={selected.includes(row.id)}
                                    disabled={isRowSelectionDisabled(row)}
                                />
                            )
                            }
                    </TableCell>
                )}

                {columnConfig.map(column => {
                    let Component = DataTableCell;

                    if (column.component) {
                        Component = column.component;
                    }

                    const complexKey = column.key.split('.');
                    const columnValue = complexKey.length > 1
                        ? complexKey.reduce((colValue, subKey) => colValue[subKey] ?? colValue, row)
                        : row[column.key];

                    return (
                        <TableCell
                            key={column.key}
                            className={clsx(styles.contentTableCell, {
                                [styles.preserveWhiteSpace]: column?.shouldPreserveWhiteSpace,
                                [styles.textWrap]: column?.shouldTextWrap,
                            })}
                            style={{width: column.width || 'auto', textAlign: column.textAlign || 'left'}}
                            {...(column.additionalCellProps || {})}
                        >
                            <Component
                                {...(!!column.componentProps && column.componentProps)}
                                rowData={row}
                                isJoyTheme={isJoyTheme}
                            >
                                {columnValue}
                            </Component>
                        </TableCell>
                    );
                })}
            </TableRow>
        ));
    }, [
        columnConfig,
        handleSelectClick,
        hasCheckboxes,
        isLoading,
        missingDataMessage,
        rows,
        rowsPerPage,
        selected,
        styles,
        onRowClick,
        isJoyTheme,
        isRowSelectionDisabled,
    ]);

    const headerTitlePositionContent = useMemo(() => {
        const searchField = (
            <TextField
                sx={{width: '100%',
                    ...(isJoyTheme && {
                        background: 'white',
                        margin: 0,
                        borderRadius: '32px',
                    })}}
                value={nonDebouncedSearchTerm}
                onChange={handleSearchTermChange}
                variant="outlined"
                margin="dense"
                size="small"
                placeholder={searchPlaceholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );

        const searchButton = (
            <IconButton
                sx={{
                    backgroundColor: 'gray_v2.100',
                    borderRadius: '8px',
                    padding: '8px 12px',
                    margin: '8px 0 4px 0',
                }}
                onClick={_ => {
                    setIsSearchActive(true);
                }}
            >
                <SearchIcon sx={{color: 'v2.light.action.active'}} />
            </IconButton>
        );

        if (hasSearch) {
            if (!isMobileSize || isSearchActive) {
                return searchField;
            }
            return searchButton;
        }

        return null;
    }, [
        isJoyTheme,
        nonDebouncedSearchTerm,
        handleSearchTermChange,
        searchPlaceholder,
        hasSearch,
        isMobileSize,
        isSearchActive,
    ]);

    const selectableRowsCount = useMemo(() => {
        if (isRowSelectionDisabled) {
            return rows.filter(row => !isRowSelectionDisabled(row)).length;
        }
        return rows.length;
    }, [isRowSelectionDisabled, rows]);

    let Container = Paper;
    let containerProps = {
        className: hasPagination ? styles.root : '',
        radius: 16,
        elevation: 2,
    };

    if (!hasPaper) {
        Container = React.Fragment;
        containerProps = {};
    }

    return (
        <Container {...containerProps}>
            {hasTabFilters && (
                <DataTableTabFilters
                    config={tabFilterConfig}
                    value={tabFilterValue}
                    onChange={handleTabFilterChange}
                    isAllDisplayed={isAllDisplayed}
                />
            )}

            {
                !customHeader
                && (!!headerTitlePositionContent || !!title)
                && (
                    <DataTableHeader
                        hasDownloadAsZipButton={hasDownloadAsZipButton}
                        isDownloadAsZipButtonDisabled={!selected.length}
                        hasColumnFilters={hasColumnFilters}
                        additionalFilters={additionalFilters}
                        onColumnFilterToggleClick={handleColumnFilterToggleClick}
                        onDownloadAsZipButtonClick={handleDownloadAsZipButtonClick}
                        isDownloadingAsZip={isDownloadingAsZip}
                        hasSearch={hasSearch}
                        hasCheckboxes={hasCheckboxes}
                        areColumnFiltersOpen={areColumnFiltersOpen}
                        title={title}
                        titleCount={titleCount}
                    >
                        {headerTitlePositionContent}
                    </DataTableHeader>
                )
            }

            {typeof customHeader === 'function' ? customHeader(headerTitlePositionContent) : customHeader}

            <TableContainer
                className={clsx(styles.tableContainer, TableContainerProps?.className)}
                {...TableContainerProps}
            >
                <Table>
                    <TableHead sx={styles.tableHead} {...TableHeadProps}>
                        <DataTableHeaderColumns
                            columnConfig={columnConfig}
                            sortDirection={sortDirection}
                            sortBy={sortBy}
                            onSortClick={handleSortClick}
                            selectedCount={selected.length}
                            count={selectableRowsCount}
                            onSelectAllClick={handleSelectAllClick}
                            hasCheckboxes={hasCheckboxes}
                            isJoyTheme={isJoyTheme}
                        />
                    </TableHead>

                    <TableBody>
                        {hasColumnFilters && areColumnFiltersOpen && (
                            <DataTableColumnFilters
                                columnConfig={columnConfig}
                                columnFilters={columnFilters}
                                createOnChangeHandler={createColumnFilterChangeHandler}
                                hasCheckboxes={hasCheckboxes}
                            />
                        )}

                        {content}
                    </TableBody>

                    {hasPagination && (
                        <DataTableFooter
                            count={count}
                            page={page > Math.max(0, Math.ceil(count / rowsPerPage) - 1) ? 0 : page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={rowsPerPageOptions}
                            isJoyTheme={isJoyTheme}
                        />
                    )}
                </Table>
            </TableContainer>
        </Container>
    );
};

DataTable.propTypes = {
    tableId: PropTypes.string.isRequired,
    title: PropTypes.string,
    titleCount: PropTypes.number,
    defaultSortByKey: PropTypes.any,
    defaultSortDirection: PropTypes.any,
    hasColumnFilters: PropTypes.bool,
    hasSearch: PropTypes.bool,
    hasPagination: PropTypes.bool,
    tabFilterConfig: PropTypes.array,
    tabFilterKey: PropTypes.oneOfType([PropTypes.string]),
    searchBy: PropTypes.arrayOf(PropTypes.string),
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    dataSelector: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasCheckboxes: PropTypes.bool,
    hasTabFilters: PropTypes.bool,
    hasDownloadAsZipButton: PropTypes.bool,
    isDownloadingAsZip: PropTypes.bool,
    hasPaper: PropTypes.bool,
    TableContainerProps: PropTypes.shape({
        className: PropTypes.string,
    }),
    TableHeadProps: PropTypes.shape({
        className: PropTypes.string,
    }),
    missingDataMessage: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string,
    additionalFilters: PropTypes.node,
    selectKey: PropTypes.string,
    defaultRowsPerPage: PropTypes.number,
    forceSortDirection: PropTypes.string,
    forceSortBy: PropTypes.string,
    // eslint-disable-next-line react/require-default-props
    onDownloadAsZipButtonClick: requiredIf(
        props => props.hasDownloadAsZipButton && typeof props.onDownloadAsZipButtonClick !== 'function',
        '"onDownloadAsZipButtonClick" must be a function if "hasDownloadAsZipButton" is true',
    ),
    onRowClick: PropTypes.func,
    isUseSavedRows: PropTypes.bool,
    customHeader: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    isAllDisplayed: PropTypes.bool,
    initialTabFilterValue: PropTypes.string,
    onSelectedChange: PropTypes.func,
    isJoyTheme: PropTypes.bool,
    isRowSelectionDisabled: PropTypes.func,
    // eslint-disable-next-line react/boolean-prop-naming
    shouldDeselectOnRowsChange: PropTypes.bool,
};

DataTable.defaultProps = {
    hasColumnFilters: false,
    isLoading: false,
    hasCheckboxes: false,
    hasTabFilters: false,
    hasDownloadAsZipButton: false,
    isDownloadingAsZip: false,
    hasSearch: false,
    hasPagination: true,
    hasPaper: true,
    TableContainerProps: {},
    TableHeadProps: {},
    title: null,
    titleCount: null,
    searchPlaceholder: 'Search',
    tabFilterConfig: [],
    tabFilterKey: null,
    searchBy: [],
    additionalFilters: null,
    defaultSortByKey: null,
    defaultSortDirection: 'desc',
    selectKey: 'id',
    defaultRowsPerPage: 10,
    forceSortDirection: undefined,
    forceSortBy: undefined,
    onRowClick: undefined,
    isUseSavedRows: true,
    customHeader: null,
    rowsPerPageOptions: [10, 25, 50],
    isAllDisplayed: true,
    initialTabFilterValue: 'ALL',
    onSelectedChange: undefined,
    isJoyTheme: false,
    isRowSelectionDisabled: () => false,
    shouldDeselectOnRowsChange: false,
};

export default DataTable;
