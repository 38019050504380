import {
    faArrowRightFromBracket,
    faArrowsRotate,
    faCaretDown,
    faCaretUp,
    faGear,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Box, List, ListItem, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ItemWithLink} from './ItemWithLink';
import Collapse from '../../../../components/joy-ui/mui-legacy/Collapse';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {USER_ROLES} from '../../../../utils/user-roles';
import {logout} from '../../../auth/store/auth.actions';

const listItemLink = {
    'maxHeight': 32,
    'cursor': 'pointer',
    '&:hover': {
        textDecoration: 'none',
    },
};

export const ProfileItemSection = ({user, isSidebarExpanded}) => {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();

    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useSelector(selectRouterLocation);

    const isActiveSettings = location.pathname === RoutePaths.SETTINGS;
    const isActiveChangePassword = location.pathname === RoutePaths.CHANGE_PASSWORD;

    useEffect(() => {
        if (location.pathname === RoutePaths.SETTINGS
            || location.pathname === RoutePaths.CHANGE_PASSWORD) {
            setIsCollapsed(true);
        }
    }, [location]);

    const handleLogOutButtonClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    if (!user) {
        return null;
    }

    const isAdmin = user.role === USER_ROLES.ADMINISTRATOR;

    return (
        <Box sx={{
            p: 2,
            backgroundColor: 'background.level2',
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: isSidebarExpanded ? 'flex-start' : 'center',
            }}
            >
                <ItemWithLink
                    to={RoutePaths.MY_PROFILE}
                >
                    <Avatar
                        size="sm"
                        variant="outlined"
                        color="primary"
                        sx={{
                            mr: isSidebarExpanded ? 1 : 0,
                        }}
                    >
                        <Box
                            color="text.icon"
                            fontSize="md"
                        >
                            <FontAwesomeIcon
                                icon={faUser}
                                fixedWidth
                            />
                        </Box>
                    </Avatar>
                </ItemWithLink>
                {isSidebarExpanded && (
                    <Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ItemWithLink
                                to={RoutePaths.MY_PROFILE}
                                sx={{...listItemLink, maxHeight: 'auto', lineHeight: '1.1rem'}}
                            >
                                <Typography
                                    level="title-sm"
                                    sx={{
                                        width: '140px',
                                        whiteSpace: 'break-spaces',
                                        color: 'text.primary',
                                    }}
                                >
                                    {user.firstName} {user.lastName}
                                </Typography>
                            </ItemWithLink>
                            <Box
                                data-cy="arrow-icon"
                                onClick={() => setIsCollapsed(collapsed => !collapsed)}
                                sx={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                            >
                                <Box
                                    color="text.icon"
                                    fontSize="md"
                                    sx={{ml: 1}}
                                >
                                    <FontAwesomeIcon
                                        icon={isCollapsed ? faCaretUp : faCaretDown}
                                        fixedWidth
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {user.defaultCompanyName && !isAdmin && (
                            <ItemWithLink
                                to={RoutePaths.MY_COMPANIES}
                                sx={{...listItemLink, maxHeight: 'auto', lineHeight: '1.1rem'}}
                            >
                                <Typography
                                    level="body-sm"
                                    sx={{
                                        color: 'text.secondary',
                                        width: '140px',
                                        whiteSpace: 'break-spaces',
                                    }}
                                >
                                    {user.defaultCompanyName}
                                </Typography>
                            </ItemWithLink>
                        )}
                        {isAdmin && (
                            <Typography
                                level="body-sm"
                                sx={{color: 'text.secondary'}}
                            >
                                {t('userMenu.admin')}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>

            <Collapse
                in={isCollapsed && isSidebarExpanded}
                timeout="auto"
                unmountOnExit
            >
                <List
                    component="ul"
                    disablePadding
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 0,
                        pt: 1.5,
                    }}
                >
                    <ItemWithLink
                        to={RoutePaths.SETTINGS}
                        sx={listItemLink}
                    >
                        <ProfileItem
                            Icon={faGear}
                            text={t('userMenu.settings')}
                            isSidebarExpanded={isSidebarExpanded}
                            isActive={isActiveSettings}
                        />
                    </ItemWithLink>

                    <ItemWithLink
                        to={RoutePaths.CHANGE_PASSWORD}
                        sx={listItemLink}
                    >
                        <ProfileItem
                            Icon={faArrowsRotate}
                            text={t('userMenu.changePassword')}
                            isSidebarExpanded={isSidebarExpanded}
                            isActive={isActiveChangePassword}
                        />
                    </ItemWithLink>

                    <Box sx={{cursor: 'pointer'}} onClick={handleLogOutButtonClick} data-cy="logout">
                        <ProfileItem
                            Icon={faArrowRightFromBracket}
                            text={t('userMenu.logout')}
                            isSidebarExpanded={isSidebarExpanded}
                            isActive={false}
                        />
                    </Box>
                </List>
            </Collapse>
        </Box>
    );
};

ProfileItemSection.propTypes = {
    user: PropTypes.object,
    isSidebarExpanded: PropTypes.bool.isRequired,
};

ProfileItemSection.defaultProps = {
    user: null,
};

const ProfileItem = ({
    Icon,
    text,
    isSidebarExpanded,
    isActive,
}) => {
    return (
        <ListItem
            component="li"
            sx={{
                py: 0,
                px: 1,
                backgroundColor: isActive ? 'neutral.plainColor' : 'transparent',
                borderRadius: 'sm',
                minBlockSize: 32,
                width: '100%',
            }}
        >
            <Box
                color={isActive ? 'primary.solidColor' : 'text.icon'}
                fontSize="sm"
            >
                <FontAwesomeIcon
                    icon={Icon}
                    fixedWidth
                />
            </Box>

            {isSidebarExpanded && (
                <Typography
                    level="body-xs"
                    sx={{
                        color: isActive ? 'primary.solidColor' : 'text.primary',
                    }}
                >
                    {text}
                </Typography>
            )}
        </ListItem>
    );
};

ProfileItem.propTypes = {
    Icon: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    isSidebarExpanded: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
};
