import moment from 'moment/moment';
import {VatDeclarationStatus} from './constants';
import {COMPANY_TVA_FREQUENCY_PAYMENTS} from '../../v1/app/company/company.consts';
import {capitalizeFirstLetter} from '../dashboard-v3/utils/constants';

export const isBiAnnual = item => {
    // TODO: Keeping SEMESTRIAL temporarily for backwards compatibility. Delete after migration.
    return (
        COMPANY_TVA_FREQUENCY_PAYMENTS[item.type] === COMPANY_TVA_FREQUENCY_PAYMENTS.SEMESTRIAL
        || COMPANY_TVA_FREQUENCY_PAYMENTS[item.type] === COMPANY_TVA_FREQUENCY_PAYMENTS.BI_ANNUALLY
    );
};

export const canViewDeclaration = item => {
    const isMonthly = item.type === COMPANY_TVA_FREQUENCY_PAYMENTS.MONTHLY;
    const isBiAnnually = isBiAnnual(item);
    const isTodo = item.status === VatDeclarationStatus.TODO;
    const isManual = item.isManual;
    return !((isMonthly || isBiAnnually) && isTodo && !isManual);
};

export const getDeclarationPeriodString = declaration => {
    if (declaration?.dueDate) {
        return capitalizeFirstLetter(moment(declaration.dueDate).subtract(1, 'months').format('MMMM YYYY'));
    }
};
