import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import AllInbox from '@mui/icons-material/AllInbox';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import DirectionsCarTwoToneIcon from '@mui/icons-material/DirectionsCarTwoTone';
import EuroIcon from '@mui/icons-material/Euro';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import InsertChartTwoToneIcon from '@mui/icons-material/InsertChartTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import SummarizeIcon from '@mui/icons-material/Summarize';

/**
 * @enum
 */
export const COMPANY_CARD_BUTTONS = {
    VIEW: 'view',
    QUICKBOOKS: 'quickbooks', // TODO STUPAR: Do not remove this, should be renamed
    DASHBOARD: 'dashboard',
    BACKOFFICE: 'backoffice',
};

/**
 * @enum
 */
export const COMPANY_PROFILE_TABS = {
    COMPANY_INFORMATION: {id: 'company-information-tab', icon: <SummarizeIcon />},
    ADDITIONAL_COMPANY_DATA: {id: 'additional-data-tab', icon: <InfoTwoToneIcon />},
    HIWAY_BANK: {id: 'hiway-bank-tab', icon: <AccountBalanceTwoToneIcon />},
    PAYSLIP: {id: 'payslip-tab', icon: <PaymentsTwoToneIcon />},
    BACK_OFFICE: {id: 'backoffice-tab', icon: <BusinessCenterTwoToneIcon />},
    INVOICING: {id: 'invoicing-tab', icon: <EuroIcon />},
    DOCUMENTS: {id: 'documents-tab', icon: <DescriptionTwoToneIcon />},
    ACCOUNTING: {id: 'accounting-tab', icon: <CalculateTwoToneIcon />},
    TRAINING: {id: 'training-tab', icon: <SchoolTwoToneIcon />},
    FORMALITIES: {id: 'formalities-tab', icon: <AllInbox />},
    BUSINESS_KILOMETERS_ALLOWANCE: {id: 'business-kilometers-allowance-tab', icon: <DirectionsCarTwoToneIcon />},
    PERSONAL_EXPENSES: {id: 'personal-expenses-tab', icon: <AccountBalanceWalletTwoToneIcon />},
    DASHBOARD: {id: 'dashboard-tab', icon: <InsertChartTwoToneIcon />},
    PROFESSIONAL_EXPENSES: {id: 'professional-expenses-tab', icon: <ReceiptLongIcon />},
};

export const HOLDING_COMPANY_PROFILE_TABS = {
    COMPANY_INFORMATION: {id: 'company-information-tab', icon: <SummarizeIcon />},
    ADDITIONAL_COMPANY_DATA: {id: 'additional-data-tab', icon: <InfoTwoToneIcon />},
    BACK_OFFICE: {id: 'backoffice-tab', icon: <BusinessCenterTwoToneIcon />},
    DOCUMENTS: {id: 'documents-tab', icon: <DescriptionTwoToneIcon />},
};

/**
 * @enum
 */
export const COMPANY_LEGAL_STATUSES = {
    SASU: 'SASU',
    EURL: 'EURL',
    EURL_HOLDING: 'EURL_HOLDING',
    SASU_PATRIMONIALE: 'SASU_PATRIMONIALE',
};

export const COMPANY_LEGAL_STATUSES_SHORT = {
    SASU: 'SASU',
    EURL: 'EURL',
    EURL_HOLDING: 'EURL_HOLDING',
};

export const CARE_COMPANY_LEGAL_STATUSES = {
    EI: 'EI',
};

/**
 * @enum
 */
export const COMPANY_REGISTRATION_TYPE = {
    COMPANY_CREATION: 'COMPANY_CREATION',
    REPRISE: 'REPRISE',
    TRANSFORMATION: 'TRANSFORMATION',
};

/**
 * @enum
 */
export const COMPANY_TVA_FREQUENCY_PAYMENTS = {
    MONTHLY: 'MONTHLY',
    SEMESTRIAL: 'SEMESTRIAL',
    BI_ANNUALLY: 'BI_ANNUALLY',
    TRIMESTRIAL: 'TRIMESTRIAL',
    NOT_DECLARABLE: 'NOT_DECLARABLE',
};

/**
 * @enum
 */
export const COMPANY_TAX_SYSTEMS = {
    IR: 'IR',
    IS: 'IS',
};

export const CARE_COMPANY_TAX_SYSTEMS = {
    BNC: 'BNC',
};

/**
 * @enum
 */
export const COMPANY_TAX_COLLECTORS = {
    URSSAF: 'URSSAF',
    URSSAF_CIPAV: 'URSSAF_CIPAV',
};
